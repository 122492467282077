<template>
    <div class="detail">
        <div class="big-title">
            {{info.location || '加载中...'}}
            <div class="small">{{info.devCode}}</div>
        </div>
        <div class="header">
            <img :src="
                info.devStatus === 'online' ? require('@/assets/fog/fog15.jpg') :
                info.devStatus === 'offline' ? require('@/assets/fog/fog15.jpg') : 
                require('@/assets/fog/fog15.jpg')
            " alt="设备图">
            <div class="info-block">
                状态:
                <van-tag v-if="info.devStatus === 'online'" type="success">在线</van-tag>
                <van-tag v-else-if="info.devStatus === 'alarm'" type="danger">告警</van-tag>
                <van-tag v-else type="warning">离线</van-tag>
            </div>
        </div>
        <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
           实时数据
        </van-divider>
        <div class="msg-content">
            <van-row class="block">
                    <van-col span="24">能见度等级：<span class="name">{{fogData.envLevel == 2 ? 
                                '良' : fogData.envLevel == 3 ? 
                                '一般' : fogData.envLevel == 4 ? 
                                '较差' : fogData.envLevel == 5 ? 
                                '差' : fogData.envLevel == 6 ?
                                '极差' :'优'}}</span>
                                </van-col>
            </van-row>
        </div>
        <!-- <div class="handle-btn">
            <van-button type="primary" @click="playVideo" v-if="mode === 'pic'">播放视频</van-button>
            <van-button type="primary" @click="stopVideo" v-if="mode === 'video'">停止播放</van-button>
            <van-button type="info" @click="takePicture"  v-if="mode === 'pic'">抓拍图片</van-button>
        </div> -->
        <!-- 视频区域 -->
        <!-- <div class="video-content" v-if="mode === 'video'">
            <video controls ref="video" muted="muted"></video>
        </div> -->
        <!-- 图片区域 v-else-if="mode === 'pic'"-->
        <div class="pic-content" >
            <van-image
                radius="5"
                class="thumb-img"
                fit="cover"
                :src="filePath"
                @click="previewImg(filePath)"
            />
            <van-loading v-if="loadingPic" color="#0094ff" vertical class="loading">加载图片...</van-loading>
        </div>
    </div>
</template>

<script>
const Hls = require('hls.js');
import { ImagePreview } from 'vant';
export default {
    data(){
        return{
            projectCode:null,
            // programCode:'gray',
            info:{
                devCode:null,
                devStatus:'offline'
            },
            fogData:{
                devName:null,
                envLevel:null
            },
            topicCamera:null,
            mode:'pic', // 当前模式 pic video
            filePath:'',
            loadingPic:false
        }
    },
    // 子组件
    inject: ['client'],
    computed: {
        clientMqtt() {
            return this.client()
        }
    },
    mounted(){
        this.projectCode = window.sessionStorage.getItem('projectCode');
        this.getBaseInfo();
        // setTimeout(()=>{
        //     console.log('mqtt实例：',this.clientMqtt);
        //     this.mqttMsg();
        // },1000)
    },
    beforeDestroy(){
        // this.unsubscribeTopic();
        // window.sessionStorage.removeItem('dev-info');
    },
    methods:{
        // 获取设备基础信息
        getBaseInfo(){
            this.info = window.sessionStorage.getItem('dev-info')? JSON.parse(window.sessionStorage.getItem('dev-info')) : {};
            // this.programCode = this.info.devStatus !== 'offline' ? 'yellow' : 'gray' 
            this.topicCamera = '/sync/mediaserver/camera/'+this.info.devCode+'/changed/upload'; // 视频Topic
            this.getNewFilePath();
        },
        // 查询最新一条图片
        getNewFilePath(){
            this.loadingPic = true;
            const data = {
                projectCode:this.projectCode,
                devCode:this.info.devCode,
            }
            this.$api.FOG.fogMonitorDevstaPage(1,1,data).then( d =>{
                console.log(d);
                if(Array.isArray(d)){
                    setTimeout(()=>{
                        this.filePath = d[0].filePath;
                        this.fogData = d[0];
                        this.loadingPic = false;
                    },800)
                }
            })
        },
        // playVideo(){
        //     this.mode = 'video';
        //     this.$toast.loading({
        //         message: '视频加载中...',
        //         forbidClick: true,
        //         duration:0
        //     });
        //     this.$api.CAMERA.cameraPlugFlow({ devCode: this.info.devCode })
        //     .then((result) => {
        //         console.log("result", result);
        //         if (result.flag) {
        //             this.getHlsStream(result.flv);
        //         } else {
        //             this.subscribeTopic();
        //             this.startCom();
        //         }
        //     });
        // },
        // stopVideo(){
        //     this.mode = 'pic';
        //     this.stopCom();
        //     this.videoHlsDestroyed();
        // },
        // takePicture(){
        //     this.camCapture();
        // },
        previewImg(url){
            ImagePreview([url]);
        },
        // 获取hls视频流
        // getHlsStream(source) {
        //     this.$toast.clear();
        //     // 处理流地址 flv转m3u8
        //     const handledSource = source.substring(0,source.indexOf("?")).replace('.flv','/hls.m3u8');
        //     if (Hls.isSupported()) {
        //         this.hls = new Hls();
        //         this.hls.loadSource(handledSource);
        //         this.hls.attachMedia(this.$refs.video);

        //         this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        //             console.log("加载成功");
        //             this.$refs.video.play();
        //         });
        //     }else if (this.$refs.video.canPlayType('application/vnd.apple.mpegurl')) {
        //         // 以下逻辑兼容IOS浏览器内核
        //         this.$refs.video.src = handledSource;
        //         this.$refs.video.addEventListener('loadedmetadata',function() {
        //             this.$refs.video.play();
        //         });
        //     }
        // },
        // videoHlsDestroyed(){
        //     if (this.hls) {
        //         this.$refs.video.pause();
        //         this.hls.destroy();
        //         this.hls = null;
        //     }
        // },
        // mqttMsg(){
        //     // 接收消息处理
        //     this.clientMqtt.on('message', (topic, message) => {
        //         const data = JSON.parse(message.toString());
        //         if (topic === this.topicCamera) {
        //             console.log(`camera--收到来着视频流${topic}的信息`, data);
        //             this.handleVideoMsg(data);
        //         }else if (topic.includes('/image/get')) {
        //             this.handlePicMsg(data);
        //         }
        //     })
        // },
        // handleVideoMsg(msg){
        //     let regist = msg.regist
        //     console.log('regist:', regist)
        //     if(regist){
        //         this.getHlsStream(msg.flv)
        //     }else{
        //         this.videoHlsDestroyed()
        //     }
        // },
        // handlePicMsg(msg){
        //     this.filePath = msg.filePath;
        //     this.loadingPic = false;
            
        //     this.programCode = 'red';

        //     this.timer = setTimeout(()=>{
        //         this.programCode = 'yellow'
        //     },5000)
        // },
        // startCom(){
        //     //发送开启指令
        //     this.$api.NEWBRIDGE.videoCommand({
        //         action:0,
        //         channel:1,
        //         wdr:1,
        //         count:180,
        //         dev_code:this.info.devCode,
        //         msg_type:12,
        //         codec:1
        //     }).then((data)=>{
        //         console.log(data,'发送开启指令');
        //     })
        // },
        // // 抓拍
        // camCapture(){
        //     this.loadingPic = true;
        //     //发送关闭指令
        //     this.$api.NEWBRIDGE.videoCommand({
        //         dev_code:this.info.devCode,
        //         msg_type:4,
        //         action:0,
        //         channel:1,
        //         count:1,
        //         codec:0,
        //         wdr:0
        //     }).then(()=>{})
        // },
        // stopCom(){
        //     //发送关闭指令
        //     this.$api.NEWBRIDGE.videoCommand({
        //         dev_code:this.info.devCode,
        //         msg_type:14
        //     }).then((data)=>{
        //         console.log(data,'发送关闭指令');
        //     })
        // },
        // subscribeTopic(){
        //     this.clientMqtt.subscribe(this.topicCamera, { qos: 1 }, (err) => {
        //         if (!err) {
        //         console.log("订阅成功!", this.topicCamera);
        //         } else {
        //         console.log("订阅失败!", this.topicCamera);
        //         }
        //     });
        // },
        // unsubscribeTopic(){
        //     this.clientMqtt.unsubscribe(this.topicCamera, { qos: 1 }, (err) => {
        //         if (!err) {
        //         console.log("取消订阅成功!", this.topicCamera);
        //         }
        //     })
        // }
    }
}
</script>

<style lang="scss" scoped>
.detail{
    .big-title{
        font-size: 1.2rem;
        font-weight: bolder;
        letter-spacing: .5px;
        text-align: center;
        padding: 10px 0;
        .small{
            padding: 10px;
            font-size: 0.85rem;
            font-weight: lighter;
        }
    }
    .header{
        padding: 10px;
        position: relative;
        img{
            border-radius: 10px;
            width: 90%;
        }
        .info-block{
            font-size: .8rem;
            position: absolute;
            bottom: 10px;
            right: 30px;
        }
    }
    .handle-btn{
        display: flex;
        justify-content: space-around;
        width: 80%;
        padding:10px 10%;
        .van-button{
            width: 40%;
        }
    }
    .video-content{
        margin: 10px 20px;
        video{
            width: 100%;
            height: 220px;
            border-radius: 10px;
        }
    }
    .pic-content{
        margin: 10px 20px;
        border: 1px solid #f0f0f0;
        border-radius: 5px;
        position: relative;
        height: 220px;
        .loading{
            position: absolute;
            bottom: 40%;
            left: 45%;
        }
        .thumb-img{
            height: 220px;
        }
    }
    .content{
            border: 1px solid #f0f0f0;
            border-radius: 10px;
            background-color: #39a29d;
            text-align: left;
            padding: 10px 20px;
            font-size: 0.9rem;
            color: #fff;
            box-shadow: 2px 2px 2px #efefef;
            .block{
                padding:8px 0;
                .important-font{
                    font-weight: bolder;
                    color: #e03e00;
                }
            }
        }
}
</style>